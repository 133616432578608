<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูล User
          </h4>
          <hr />
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Username</label>
                <input type="text" v-model="result.username" class="form-control" placeholder="ข้อมูลเบอร์มือถือ" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ชื่อบัญชี</label>
                <input type="text" v-model="result.full_name" class="form-control" placeholder="ข้อมูลชื่อบัญชี" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Line</label>
                <input type="text" v-model="result.line_id" class="form-control" placeholder="ข้อมูล Line" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Username (member)</label>
                <input type="text" v-model="result.account_agent_username" class="form-control" placeholder="ข้อมูล Username (member)" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Password (member)</label>
                <input type="text" v-model="result.account_agent_password" class="form-control" placeholder="ข้อมูล Password (member)" readonly>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลธนาคาร
          </h4>
          <hr />
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ธนาคาร</label>
                <b-form-select v-model="result.bank" :options="option_bank" disabled/>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">เลขบัญชี</label>
                <input type="text" v-model="result.bank_number" class="form-control" placeholder="ข้อมูลเลขบัญชี" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ชื่อบัญชี</label>
                <input type="text" v-model="result.full_name" class="form-control" placeholder="ข้อมูลชื่อบัญชี" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">จำนวนเงิน</label>
                <input type="text" v-model="result.amount" class="form-control" placeholder="ข้อมูลจำนวนเงิน" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ถอนเมื่อ</label>
                <input type="text" v-model="result.created_at" class="form-control" placeholder="ข้อมูลถอนเมื่อ" readonly>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดท้าย -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลโปรโมชั่น
          </h4>
          <hr />
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ชื่อโปรโมชั่น</label>
                <input type="text" v-model="result.promotion_name" class="form-control" placeholder="ข้อมูลชื่อโปรโมชั่น" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">จำนวนโบนัส (%)</label>
                <input type="text" v-model="result.percent" class="form-control" placeholder="ข้อมูลจำนวนโบนัส (%)" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">โบนัสสูงสุด (บาท)</label>
                <input type="text" v-model="result.max_value" class="form-control" placeholder="ข้อมูลโบนัสสูงสุด (บาท)" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">คูณยอดเทิร์น</label>
                <input type="text" v-model="result.turn" class="form-control" placeholder="ข้อมูลคูณยอดเทิร์น" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">โบนัส</label>
                <input type="text" v-model="result.bonus" class="form-control" placeholder="ข้อมูลโบนัส" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">รวม</label>
                <input type="text" v-model="result.amount" class="form-control" placeholder="ข้อมูลรวม" readonly>
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <router-link :to="PATH" class="btn bg-gradient-warning waves-effect waves-light mr-1"><span><feather-icon icon="ArrowLeftIcon" class="font-medium-1" /></span>ย้อนกลับ</router-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BTable,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      PATH: '/deposit',
      BASE_URL: process.env.BASE_URL,
      API_GET_URL: 'deposit/deposit_detail/' + this.$route.params.id,
      result: {
        username: '',
        full_name: '',
        line_id: '',
        account_agent_username: '',
        account_agent_password: '',
        bank: '',
        bank_number: '',
        bank_name: 1,
        amount: 1,
        created_at: '',
        promotion_name: '',
        percent: 0,
        max_value: 0,
        turn: 0,
        bonus: 0,
        amount: 0,
      },
      option_bank: [
        { text: 'ธนาคารกรุงเทพ จำกัด (มหาชน)', value: '01' },
        { text: 'ธนาคารกสิกรไทย จำกัด (มหาชน)', value: '02' },
        { text: 'ธนาคารกรุงไทย จำกัด (มหาชน)', value: '03' },
        { text: 'ธนาคารทหารไทยธนชาต จำกัด (มหาชน)', value: '04' },
        { text: 'ธนาคารไทยพาณิชย์ จำกัด (มหาชน)', value: '05' },
        { text: 'ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)', value: '06' },
        { text: 'ธนาคารออมสิน จำกัด (มหาชน)', value: '07' },
        { text: 'ธนาคารธนชาติ จำกัด (มหาชน)', value: '08' },
        { text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร จำกัด (มหาชน)', value: '09' },
      ],
      option: [
        { text: 'ปิด', value: '0' },
        { text: 'เปิด', value: '1' },
      ],
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        onBeforeOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    fetch() {
      this.$http.get(this.API_GET_URL).then(res => {
        if (res.data != null && res.data.result != null) {
          this.result = res.data.result
          this.result.bank_code = this.option_bank.find(b=>b.value==this.result.bank_code)
          this.result.bonus = 0
        }
        else this.$router.push(this.PATH)
      }).catch(()=>this.$router.push(this.PATH))
    },
    slip() {

    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
